import React, { useState, useEffect } from 'react';
import { Button } from '@shared/components/Button';
import { Tab, Tabs, Box, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Select, MenuItem, useTheme, DialogTitle, Typography, Tooltip, Chip, IconButtonProps, styled, IconButton, Divider, Stack } from '@mui/material';
import { messages } from '../../procedures.messages';
import { toast } from 'react-toastify';
import { ToastMessage } from '@shared/components/Toast';
import { useProceduresUIStore, useProcedureUIStore, useUserPermissionsUIStore } from '@core/useStores';
import { ThemeProps } from '@styles/theme';
import { useStyles } from './VersionAssignmentModal.styles';
import { IStoresEnv } from '@core/storesEnv';
import { getEnv } from 'mobx-state-tree';
import { IProcedureVersionStore, ProcedureVersionStore } from 'Procedures/stores/Procedure.store';
import { ArrayDropdown } from '@shared/components/SelectDropdown/ArrayDropdown';
import { Modal } from '@shared/components/Modal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import { ISelectOption } from '@shared/helpers/form/ISelectOption';
import { DeviceIcon } from '@shared/components/DeviceIcon';
import { IProcedureDevice } from 'Procedures/stores';
import { ExpandButton } from '@shared/components/Button/ExpandButton';
import { COMMON_SELECT_PROPS } from '../ProcedureModal/components/ProcedureDetailsForm';
import { ProcedureVersionAssignment } from 'Procedures/services';
import FallbackProcedureIcon from '@assets/fallback-procedure-icon.png';
import { ExecutionModes } from '@shared/components/ExecutionModes';
import LanguageIcon from '@mui/icons-material/Language';

type VersionRowData = {
  version: IProcedureVersionStore;
  users: ISelectOption[];
  groups: ISelectOption[];
};

interface VersionAssignmentModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedProcedureId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface VersionRowProps {
  version: IProcedureVersionStore;
  actors: ISelectOption[];
  allActors: ISelectOption[];
  portalTarget?: HTMLElement | null;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (values: ReadonlyArray<object>, fieldId: string) => void;
}

interface DeviceType {
  type: string | null;
  platform: string | null;
  environments: string | null;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`assignment-tabpanel-${index}`}
      aria-labelledby={`assignment-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const VersionRow: React.FC<VersionRowProps> = (data: VersionRowProps) => {
  const theme = useTheme<ThemeProps>();
  const styles = useStyles({ theme });

  const { version, actors, allActors, onChange } = data;

  const [expanded, setExpanded] = useState(false);
  const MAX_LENGTH = 100; // Set the max length before truncation

  const truncatedDescription =
    !version.description ? ''
      : version.description.length > MAX_LENGTH && !expanded
      ? version.description.substring(0, MAX_LENGTH) + '...'
      : version.description;

  const getLanguage = new Intl.DisplayNames(['EN'], { type: 'language' });

  const devices: DeviceType[] = version.devices.map((d: IProcedureDevice) => ({
          type: d.device,
          platform: d.platform,
          environments: d.environments.join('|'),
      }));

  const uniqueDevices = _.uniqBy(devices, (device) => device.platform + '-' + device.environments);

  return (
    <TableRow key={version.id}>
      <TableCell>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', mb: 2 }}>
            <Tooltip title={new Date(version.date).toLocaleString()} arrow>
              <p style={{color: 'rgba(0.5, 0.5, 0.5, 0.4)'}}>{new Date(version.date).toLocaleDateString()}</p>
            </Tooltip>
            {version.editorVersion &&
              <Tooltip title={<Box>
                  <Typography variant="body2" color="textSecondary" sx={{ color: '#ffffff'}}>
                    {messages['versionAssignmentModal.editorVersion.tooltip']}
                  </Typography>
                </Box>} arrow>
                <Chip
                  sx={{ ml: 4, height: 16}}
                  label={version.editorVersion}
                  color="primary"
                  variant='outlined'
                  size="small"
                />
              </Tooltip>
            }
            {version.languages && version.languages.length > 0 &&
              <Stack direction="row" spacing={1} sx={{ ml: 4 }}>
                <Tooltip title={messages['versionAssignmentModal.languages.tooltip']} arrow>
                  <LanguageIcon fontSize="small" />
                </Tooltip>
                <Divider orientation="vertical" flexItem variant='middle' style={{marginRight: 1, marginLeft: 2}} />
                {version.languages.map((lang: string, index: number) => (
                  <Tooltip key={index} title={getLanguage.of(lang)} arrow>
                    <Typography variant="body2" color="textSecondary">
                      {lang} {index < version.languages.length - 1 ? ' - ' : ''}
                    </Typography>
                  </Tooltip>
                ))}
              </Stack>
            }
          </Box>
          <Box sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    color: 'text.secondary',
                    p: '1px 2px 1px 8px',
                    ml: -1,}}>
            <Typography style={{minWidth: 32}} variant="h5" color='primary'>{version.version}</Typography>
            <Divider orientation="vertical" flexItem variant='middle' style={{marginRight: 8, marginLeft: 8}} />
            {uniqueDevices.map((device: DeviceType, index: number) =>
                  <DeviceIcon
                  platform={device.platform ?? ''}
                  environments={device.environments}
                  size='small'
                  id={index}
                  />
                )}
            <Divider orientation="vertical" flexItem variant='middle' style={{marginRight: 8, marginLeft: 8}} />
            <ExecutionModes modes={version.executionModes} size='small' />
          </Box>
          {version.description &&
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
              <Typography variant="body2" color="textSecondary">
                {truncatedDescription}
              </Typography>
              {version.description && version.description.length > MAX_LENGTH && (
                <Tooltip title={messages['versionAssignmentModal.expandDescription.tooltip']} arrow>
                  <ExpandButton
                    expand={expanded}
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandButton>
                </Tooltip>
              )}
            </Box>
          }
        </Box>
      </TableCell>
      <TableCell style={{ paddingTop: 41, verticalAlign: 'initial'}}>
        <ArrayDropdown
            fieldId={version.id}
            values={actors}
            options={allActors}
            onChange={onChange}
            menuPortalTarget={document.body}
            placeholder={data.placeholder}
            disabled={data.disabled}
            {...COMMON_SELECT_PROPS}
        />
      </TableCell>
    </TableRow>
  )
}

export const VersionAssignmentModal: React.FC<VersionAssignmentModalProps> = ({
  isOpen,
  onRequestClose,
  selectedProcedureId: procedureId,
}) => {

  const theme = useTheme<ThemeProps>();
  const styles = useStyles({ theme });

  const proceduresUIStore = useProceduresUIStore();
  const procedure = proceduresUIStore.procedures.find(p => p.id === procedureId);

  if(!procedure) {
    return null;
  }

  const proceduresGridUIStore = useProceduresUIStore();
  const procedureUIStore = useProcedureUIStore();
  const userPermissionsUIStore = useUserPermissionsUIStore();

  const { filtersOptions } = getEnv<IStoresEnv>(proceduresGridUIStore);

  const allGroups = filtersOptions.getGroupsOptions;
  const allUsers = filtersOptions.getUsersOptions;

  const users = procedure.users;
  const groups = procedure.groups;
  const versions = _.orderBy(procedure.versions, ['date'], ['desc']);
  const latestVersion: IProcedureVersionStore = ProcedureVersionStore.create({
    id: 'latest',
    version: 'Latest',
    date: versions[0].date,
    description: null,
    executionModes: _.uniqBy(versions.flatMap(v => v.executionModes), (mode) => mode),
    environments: _.uniqBy(versions.flatMap(v => v.environments), (env) => env),
    devices: _.uniqBy(versions.flatMap(v => v.getRawDevices), (device) => device.platform),
  });
  const versionMap = new Map(versions.map(version => [version.id, version]));

  const [activeTab, setActiveTab] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [versionsMap, setVersionsMap] = useState<Map<string, VersionRowData>>(new Map());

  useEffect(() => {
    // Initialize assignments from props
    const vMap = new Map<string, VersionRowData>();

    users.forEach(user => {
      const actualUser = allUsers.find(u => u.value === user.id);
      const userToAdd = actualUser || { value: user.id, label: user.name };
      const versionId = user.data?.assignedVersion ? user.data.assignedVersion : 'latest';
      const version = vMap.get(versionId);
      if(version) {
        version.users.push(userToAdd);
      }
      else {
        vMap.set(versionId, {
          version: versionId === 'latest' ? latestVersion : (versionMap.get(versionId) ?? latestVersion),
          users: [userToAdd],
          groups: [],
        });
      }
    });

    groups.forEach(group => {
      const actualGroup = allGroups.find(u => u.value === group.id);
      const groupToAdd = actualGroup || { value: group.id, label: group.name };
      const versionId = group.data?.assignedVersion ? group.data.assignedVersion : 'latest';
      const version = vMap.get(versionId);
      if(version) {
        version.groups.push(groupToAdd);
      }
      else {
        vMap.set(versionId, {
          version: versionId === 'latest' ? latestVersion : (versionMap.get(versionId) ?? latestVersion),
          users: [],
          groups: [groupToAdd],
        });
      }
    });

    setVersionsMap(vMap);
  }, [users, groups]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleAssignmentChanged = (isUser: boolean, values: ReadonlyArray<object>, fieldId: string) => {
    const version = versionsMap.get(fieldId);
    if(!version) {
       const newVersion = versionMap.get(fieldId) ?? latestVersion;
       const newValues = values as ISelectOption[];
       const newMap = new Map(versionsMap);
       newMap.set(fieldId, {
         version: newVersion,
         users: isUser ? newValues : [],
         groups: isUser ? [] : newValues,
       });
       setVersionsMap(newMap);
       return;
    }

    const newValues = values as ISelectOption[];

    if(isUser) {
      version.users = newValues;
    }
    else {
      version.groups = newValues;
    }

    // Create a new version of the map to trigger a re-render
    const newVersionsMap = new Map(versionsMap);

    setVersionsMap(newVersionsMap);
  };

  const handleSave = async () => {
    setIsSubmitting(true);

    try {
      // Prepare assignments for API call
      const assignments: Array<ProcedureVersionAssignment> = [];
      const alreadyAssignedUsers: Array<ISelectOption> = [];
      const alreadyAssignedGroups: Array<ISelectOption> = [];

      const latestVersionData = versionsMap.get('latest')!;
      if(latestVersionData) {
        assignments.push({
          id: procedureId,
          versionId: null,
          userIds: latestVersionData.users?.map(user => user.value),
          groupIds: latestVersionData.groups?.map(group => group.value),
        });

        alreadyAssignedUsers.push(...latestVersionData.users);
        alreadyAssignedGroups.push(...latestVersionData.groups);
      }

      versionsMap.forEach((versionData, versionId) => {
        if(versionId === 'latest') {
          return;
        }

        const version = versionMap.get(versionId);
        if(!version) {
          return;
        }

        const users = versionData.users.filter(user => !alreadyAssignedUsers.find(u => u.value === user.value));
        const groups = versionData.groups.filter(group => !alreadyAssignedGroups.find(g => g.value === group.value));

        assignments.push({
          id: procedureId,
          versionId: versionId,
          userIds: users.map(user => user.value),
          groupIds: groups.map(group => group.value),
        });

        alreadyAssignedUsers.push(...users);
        alreadyAssignedGroups.push(...groups);
      });

      const result = await procedureUIStore.saveVersionAssignments(assignments);

      if (result.success) {
        // Update the procedure with the new assignments
        proceduresGridUIStore.load();
        toast.success(
          <ToastMessage
          message={messages['versionAssignmentModal.success']}
          type='success'
          />
        );
        onRequestClose();
      } else {
        toast.error(
          <ToastMessage
            message={messages['versionAssignmentModal.error']}
            type='error'
          />
        );
      }
    } catch (error) {
      toast.error(
        <ToastMessage
          message={messages['versionAssignmentModal.error']}
          type='error'
        />
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
    >
      <DialogTitle sx={{ zIndex: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {
                        procedure.procedurePreview ? (
                                <img
                                    className={styles.procedureImage}
                                    src={procedure.procedurePreview.src}
                                    alt={procedure.procedurePreview.description}
                                />
                            )
                            : (
                              <img
                                  className={styles.procedureImage}
                                  src={FallbackProcedureIcon}
                              />
                            )
          }
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2, alignItems: 'flex-start', flexGrow: 1, minHeight: 120, p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="h5" color='primary'>
                {procedure.name}
              </Typography>
              <Paper elevation={2} sx={{pt: 1, pl: 2, pr: 2, mt: 1, mb: 1, ml: 4, fontSize: 16}}>{procedure.type}</Paper>
            </Box>
            <Typography variant="body2" color='textSecondary' sx={{ flexGrow: 1 }}>
              {procedure.description}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>

      <Box sx={{ width: '100%', height: '100%', padding: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="version assignment tabs">
            <Tab label={messages['versionAssignmentModal.users.tab']} />
            <Tab label={messages['versionAssignmentModal.groups.tab']} />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <TableContainer component={Paper} sx={{ overflow: 'scroll', flexGrow: 1, maxHeight: 500 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{messages['versionAssignmentModal.table.versions']}</TableCell>
                  <TableCell className={styles.actorsColumn}>{messages['versionAssignmentModal.table.users']}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <VersionRow
                    version={latestVersion}
                    actors={versionsMap.get(latestVersion.id)?.users ?? []}
                    onChange={(values, fieldId) => handleAssignmentChanged(true, values, fieldId)}
                    allActors={allUsers}
                    placeholder={messages['versionAssignmentModal.dropdown.noUsersAssigned']}
                    disabled={!userPermissionsUIStore.canChangeAssignedUsers}
                />
                {versions.map((version) => (
                  <VersionRow
                    version={version}
                    actors={versionsMap.get(version.id)?.users ?? []}
                    allActors={allUsers}
                    onChange={(values, fieldId) => handleAssignmentChanged(true, values, fieldId)}
                    placeholder={messages['versionAssignmentModal.dropdown.noUsersAssigned']}
                    disabled={!userPermissionsUIStore.canChangeAssignedUsers}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
        <TableContainer component={Paper} sx={{ overflow: 'scroll', flexGrow: 1, maxHeight: 500 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{messages['versionAssignmentModal.table.versions']}</TableCell>
                  <TableCell className={styles.actorsColumn}>{messages['versionAssignmentModal.table.groups']}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <VersionRow
                    version={latestVersion}
                    actors={versionsMap.get(latestVersion.id)?.groups ?? []}
                    onChange={(values, fieldId) => handleAssignmentChanged(false, values, fieldId)}
                    allActors={allGroups}
                    placeholder={messages['versionAssignmentModal.dropdown.noGroupsAssigned']}
                    disabled={!userPermissionsUIStore.canChangeAssignedGroups}
                />
                {versions.map((version) => (
                  <VersionRow
                    version={version}
                    actors={versionsMap.get(version.id)?.groups ?? []}
                    allActors={allGroups}
                    onChange={(values, fieldId) => handleAssignmentChanged(false, values, fieldId)}
                    placeholder={messages['versionAssignmentModal.dropdown.noGroupsAssigned']}
                    disabled={!userPermissionsUIStore.canChangeAssignedGroups}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Box>

      <Box sx={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end', mt: 3, p: 2 }}>
        <Button
          onClick={onRequestClose}
          color="secondary"
          sx={{ mr: 4 }}
        >
          {messages['versionAssignmentModal.cancel']}
        </Button>
        <Button 
          onClick={handleSave}
          color="primary"
          disabled={isSubmitting}
        >
          {messages['versionAssignmentModal.save']}
        </Button>
      </Box>
    </Modal>
  );
}; 