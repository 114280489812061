import { Instance, SnapshotIn, types, applySnapshot } from 'mobx-state-tree';

import { ProcedureDevice } from './ProcedureDevice.store';
import { DeviceData, DeviceTabInfo } from '../domain/Device';
import _ from 'lodash';

export const ProcedureDeviceUIInferred = types.model({
    _device: types.maybeNull(ProcedureDevice),
    isLoading: types.optional(types.boolean, false),
    replaceConfirmationVisible: types.optional(types.boolean, false),
    deleteConfirmationVisible: types.optional(types.boolean, false),
    isUpdatingMode: types.optional(types.boolean, false),
    isCanceled: types.optional(types.boolean, false),
    hasError: types.optional(types.boolean, false),
    progress: types.optional(types.number, 0),
    error: types.maybeNull(types.string),
    uiId: types.string,
})
    .views(self => ({
        get name() {
            return self._device?.device;
        },

        get environments() {
            return self._device?.environments;
        },

        get platform() {
            return self._device?.platform;
        },

        get fullName() {
            const validEnvironment = self._device?.environments ? _.remove(self._device.environments, (env) => env.toLowerCase() === 'screen2d').join('|') : null;
            return validEnvironment ? `${self._device?.device} - ${validEnvironment}` : self._device?.device;
        },

        get isUploaded() {
            return !!self._device?.deviceId;
        },

        get canBeCanceled() {
            return self.isLoading && self.progress < 100;
        },

        get isActionsBlockVisible() {
            return !self.isLoading && !self.deleteConfirmationVisible && !self.replaceConfirmationVisible;
        },
    }))
    .actions(self => {
        const toggleLoading = (isLoading: boolean) => self.isLoading = isLoading;
        const setProgress = (progress: number) => self.progress = Math.round(progress);
        const updateDevice = (data: DeviceData) => self._device && self._device.updateDevice(data);
        const setDeviceError = (error: string) => {
            self.hasError = true;
            self.error = error;
        };
        const clearError = () => {
            self.hasError = false;
            self.error = null;
        }
        const toggleCanceledState = (canceled: boolean) => self.isCanceled = canceled;
        const toggleReplaceConfirmation = (visible: boolean) => self.replaceConfirmationVisible = visible;
        const toggleDeleteConfirmation = (visible: boolean) => self.deleteConfirmationVisible = visible;
        const toggleUpdatingMode = (updating: boolean) => self.isUpdatingMode = updating;

        const replaceDevice = (existingUIId: string) => {
            applySnapshot(self, {
                _device: {
                    device: 'New device',
                },
                isLoading: false,
                isUpdatingMode: false,
                deleteConfirmationVisible: false,
                replaceConfirmationVisible: false,
                isCanceled: false,
                hasError: false,
                progress: 0,
                error: null,
                uiId: existingUIId,
            });
        }

        return {
            toggleLoading,
            setProgress,
            updateDevice,
            replaceDevice,
            setDeviceError,
            clearError,
            toggleReplaceConfirmation,
            toggleDeleteConfirmation,
            toggleUpdatingMode,
            toggleCanceledState,
        }
    });

type ProcedureDeviceUIFactoryType = typeof ProcedureDeviceUIInferred;
interface IProcedureDeviceUIFactory extends ProcedureDeviceUIFactoryType { }
export const ProcedureDeviceUI: IProcedureDeviceUIFactory = ProcedureDeviceUIInferred;
export interface IProcedureDeviceUI extends Instance<IProcedureDeviceUIFactory> { }
export interface IProcedureDeviceUISnapshotIn extends SnapshotIn<IProcedureDeviceUI> { }
