import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { ThemeProps } from '@styles/theme';
import { useTheme } from '@mui/material/styles';

import VRIcon from '@assets/vr.svg';
import ARIcon from '@assets/ar.svg';
import MobileIcon from '@assets/tablet.svg';
import PCIcon from '@assets/pc.svg';
import PCVRIcon from '@assets/pc-vr.svg';
import AndroidIcon from '@assets/android.svg';
import AndroidARIcon from '@assets/android-ar.svg';
import AndroidVRIcon from '@assets/android-vr.svg';
import IOSIcon from '@assets/ios.svg';
import IOSARIcon from '@assets/ios-ar.svg';
import IOSVRIcon from '@assets/ios-vr.svg';
import GenericAR from '@assets/ar.svg';
import _ from 'lodash';
import classNames from 'classnames';
import { Tooltip } from '@mui/material';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';


type DeviceIconProps = {
    id?: number;
    platform: string;
    environments?: string | null;
    size?: 'small' | 'normal';
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    deviceIcon: {
        paddingRight: theme.spacing(1),
    },
    deviceIconSmall: {
        maxWidth: '28px',
        maxHeight: '28px',
    },
}))

export const DeviceIcon: FC<DeviceIconProps> = props => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const { id, platform, environments, size} = props;

    const deviceTypesImages = {
        'VR': VRIcon,
        'AR': ARIcon,
        'Mobile': MobileIcon,
        'PC': PCIcon,
        'pc': PCIcon,
        'pc-vr': PCVRIcon,
        'windows': PCIcon,
        'windows-screen2d': PCIcon,
        'windows-vr': PCVRIcon,
        'osx': PCIcon,
        'osx-screen2d': PCIcon,
        'osx-vr': PCVRIcon,
        'android': AndroidIcon,
        'android-screen2d': AndroidIcon,
        'android-ar': AndroidARIcon,
        'android-vr': AndroidVRIcon,
        'android-mr': AndroidVRIcon,
        'ios': IOSIcon,
        'ios-screen2d': IOSIcon,
        'ios-ar': IOSARIcon,
        'ios-vr': IOSVRIcon,
        'ios-mr': IOSVRIcon,
        'visionos-vr': IOSVRIcon,
        'visionos-mr': IOSVRIcon,
        'visionos-ar': IOSVRIcon,
        'wsa': GenericAR,
    } as { [key: string]: string };

    // remove the 'screen2d' suffix from the environment
    const getIconData = (platform: string, environment: string | null) => {
        const environmentSuffix = environment?.toLowerCase().replace('screen2d', '');
        const key = platform.toLowerCase() + (environmentSuffix ? '-' + environmentSuffix : '');
        const title = platform + (environmentSuffix ? ' ' + environment : '');
        const imgSrc = deviceTypesImages[key] ?? deviceTypesImages[platform] ?? DeviceUnknownIcon;

        return { imgSrc, title, key: id + key };
    }

    return (
        _.uniqBy(environments?.split('|').map(env => getIconData(platform, env)), v => v.imgSrc).map(({ imgSrc, title, key }) =>
            <Tooltip title={title} key={key} arrow>
                <img
                    className={classNames(styles.deviceIcon, { [styles.deviceIconSmall]: size === 'small' })}
                    src={imgSrc}
                    alt={platform ?? ''}
                    title={title}
                    key={key}
                />
            </Tooltip>
        )
    )
}
