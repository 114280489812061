import { Instance, SnapshotIn, types, getEnv, flow, applySnapshot } from 'mobx-state-tree';
import { Subject } from 'rxjs';
import _ from 'lodash';

import { IStoresEnv } from '@core/storesEnv';
import {
    performGetProcedureProcessToken, performUploadFile, performCancelProcess,
    performCreateProcedure, performEditProcedure, performGetProcedure,
    performDeleteProcedure, performCancelDeviceProcess
} from 'Procedures/services';
import { performExportProcedure } from 'Procedures/services/exportProcedure';
import { EditProcedurePayload } from 'Procedures/domain/EditProcedurePayload';
import { CreateProcedurePayload } from 'Procedures/domain/CreateProcedurePayload';
import { ProcedureDeviceUI, IProcedureDeviceUI } from './ProcedureDeviceUI.store';

const newDevice = () => {
    return {
        _device: { device: 'New device' },
        uiId: _.uniqueId('tab_'),
    }
}

type UploadData = {
    token: string;
    procedureId: string;
    deviceId: string;
}

export const ProcedureEditorStoreInferred = types
    .model('ProcedureEditorStoreInferred', {
        devices: types.optional(types.array(ProcedureDeviceUI), [newDevice()]),
    })
    .actions(self => {
        const { api } = getEnv<IStoresEnv>(self);
        const cancelSubjects: Subject<{}>[] = [];

        const getToken = flow(function* () {
            const result = yield performGetProcedureProcessToken(
                api,
                {
                    payload: {},
                    errorHandlers: { DEFAULT: '' },
                    disableDefaultErrorHandlers: true,
                },
            );

            return result;
        });

        const uploadFile = flow(function* (
            file: File,
            uploadData: Partial<UploadData>,
            device: IProcedureDeviceUI,
            cancelSubject: Subject<{}>
        ) {
            // Save all cancelSubjects to clear pending requests
            // if user will Cancel work with modal
            cancelSubjects.push(cancelSubject);

            const result = yield performUploadFile(
                api,
                {
                    payload: { file, ...uploadData, token: uploadData.token! },
                    errorHandlers: { DEFAULT: '' },
                    disableDefaultErrorHandlers: true,
                    cancelSubject,
                },
                progress => device.setProgress(progress),
            );

            return result;
        });

        const createProcedure = flow(function* (payload: CreateProcedurePayload) {
            const result = yield performCreateProcedure(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                    disableDefaultErrorHandlers: true,
                },
            );

            return result;
        });

        const editProcedure = flow(function* (payload: EditProcedurePayload) {
            const result = yield performEditProcedure(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                    disableDefaultErrorHandlers: true,
                },
            );

            return result;
        });

        const loadProcedure = flow(function* (id: string) {
            const result = yield performGetProcedure(
                api,
                {
                    payload: { id },
                    errorHandlers: { DEFAULT: '' },
                },
            );

            return result;
        });

        const deleteProcedure = flow(function* (id: string) {
            const result = yield performDeleteProcedure(
                api,
                {
                    payload: { id },
                    errorHandlers: { DEFAULT: '' },
                },
            );

            return result;
        });

        const exportProcedure = flow(function* (payload: any) {
            const result = yield performExportProcedure(
                api,
                {
                    payload,
                    errorHandlers: { DEFAULT: '' },
                },
            );

            return result;
        });

        // TODO: add type
        // TODO Check is it correct and not rewrite previos devices
        const setDevices = (devices: any) => applySnapshot(self.devices, devices);

        const addDevice = () => {
            const newDevices = [...self.devices, newDevice()];

            applySnapshot(self.devices, newDevices);
        };

        const cancelProcedureFormProcess = flow(function* (token: string) {
            // Cancel all File upload requests
            cancelSubjects.forEach(subject => subject.next({}));

            const result = yield performCancelProcess(
                api,
                {
                    payload: { token },
                    errorHandlers: { DEFAULT: '' },
                    disableDefaultErrorHandlers: true,
                },
            );

            return result;
        });

        const cancelProcedureDeviceProcess = flow(function* (token: string, id: string) {
            const result = yield performCancelDeviceProcess(
                api,
                {
                    payload: { token, id },
                    errorHandlers: { DEFAULT: '' },
                    disableDefaultErrorHandlers: true,
                },
            );

            return result;
        });

        const deleteDeviceFromList = (index: number) => {
            const newDevices = [...self.devices];

            newDevices.splice(index, 1);
            applySnapshot(self.devices, newDevices);

            if (newDevices.length === 0) {
                addDevice();
            }
        };

        const clearDevices = () => {
            applySnapshot(self.devices, [newDevice()]);
        }

        return {
            getToken,
            uploadFile,
            editProcedure,
            createProcedure,
            loadProcedure,
            deleteProcedure,
            exportProcedure,
            setDevices,
            addDevice,
            cancelProcedureFormProcess,
            cancelProcedureDeviceProcess,
            deleteDeviceFromList,
            clearDevices,
        }
    })

type ProcedureEditorStoreFactoryType = typeof ProcedureEditorStoreInferred;
interface IProcedureEditorStoreFactory extends ProcedureEditorStoreFactoryType { }
export const ProcedureEditorStore: IProcedureEditorStoreFactory = ProcedureEditorStoreInferred;
export interface IProcedureEditorStore extends Instance<IProcedureEditorStoreFactory> { }
export interface IProcedureEditorStoreSnapshotIn extends SnapshotIn<IProcedureEditorStore> { }
