import { ApiResponse } from '@ace/core';
import { IApiStore } from '@core/api/apiStore';
import { EndpointCallParams } from '@core/api/endpointCallParams';

export interface ProcedureVersionAssignment {
  id: string;
  versionId: string | null;
  userIds: string[] | [] | null;
  groupIds: string[] | [] | null;
}

export const assignProcedureVersions = async (
  api: IApiStore,
  params: EndpointCallParams<ProcedureVersionAssignment[]>
): Promise<ApiResponse> => {

  const { payload, ...rest } = params;
  const req = api.post('content-app/api/v1/Procedures/SpecificAssignment', { ...rest, payload });

  return req;
};