import { createUseStyles } from 'react-jss';
import { ThemeProps } from '@styles/theme';
import { flex } from '@resources/mixins';

export const useStyles = createUseStyles((theme: ThemeProps) => ({
  modal: {
    width: theme.spacing(250),
    maxWidth: 'calc(100vw - 200px)',
    // height: 500,
    minHeight: 'calc(100vh - 200px)',
    maxHeight: 'calc(100vh - 20px)',
    overflowY: 'auto',
    borderRadius: 8,
    '& .modalInnerContent': {
      overflowY: 'visible',
    },
  },
  tabs: {
    marginBottom: 16,
  },
  tabPanel: {
    // height: 300,
    overflowY: 'auto',
  },
  table: {
    width: '100%',
    maxHeight: 200,
    borderCollapse: 'collapse',
  },
  tableHeader: {
    // backgroundColor: theme.background.default,
    '& th': {
      padding: 12,
      fontWeight: 600,
      textAlign: 'left',
      // borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  tableRow: {
    '&:hover': {
      // backgroundColor: theme.,
    },
    '& td': {
      padding: 12,
      // borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  actorCell: {
    width: '60%',
  },
  versionCell: {
    width: '40%',
  },
  dropdown: {
    width: '100%',
  },
  dialogActions: {
    padding: '16px 24px !important',
  },
  loadingOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
  },
  removeButton: {
    width: theme.spacing(12),
    minWidth: 'auto',
    height: 36,
    paddingRight: theme.spacing(2),
    margin: 0,
    marginRight: theme.spacing(2),
    right: 0,
    backgroundColor: theme.colors.red,

    '&:hover': {
      backgroundColor: theme.colors.deep_red,
    }
  },
  procedureImage: {
        maxWidth: 240,
        height: 120,
        objectFit: 'cover',
        marginRight: theme.spacing(3),
        borderRadius: theme.radius,
        backgroundColor: theme.colors.grey_light,
        ...flex('row', 'center', 'center'),
        flexShrink: 0,
  },

  actorsColumn: {
    width: '65%',
  },

  actorsCell: {
    ...flex('row', 'flex-start', 'flex-start'),
    flexWrap: 'wrap',
  },
}));