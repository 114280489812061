import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { ThemeProps } from '@styles/theme';
import { useTheme } from '@mui/material/styles';

import _ from 'lodash';
import { Box, Tooltip, Typography } from '@mui/material';
import { flex } from '@resources/mixins';
import classNames from 'classnames';
import { messages } from '../components.messages';


type ExecutionModesProps = {
    modes: string[];
    size?: 'small' | 'normal';
}

const useStyles = createUseStyles((theme: ThemeProps) => ({
    deviceIcon: {
        paddingRight: theme.spacing(1),
    },
    deviceIconSmall: {
        maxWidth: '28px',
        maxHeight: '28px',
    },
    modesContainer: {
            ...flex('row', 'center', 'flex-start'),
    },

    normal: {
        fontSize: 18,
        backgroundColor: theme.colors.transp_violet,
        fontStyle: 'bold',
        padding: '6px 12px',
        marginRight: 8,
        borderRadius: theme.radius,
        fontFamily: theme.font.secondary,

        '&.MuiTypography-root': {
            // color: theme.colors.white,
            fontStyle: 'bold',
        }
    },
    small: {
        fontSize: 18,
        backgroundColor: theme.colors.transp_violet,
        fontStyle: 'bold',
        padding: '2px 8px',
        marginRight: 4,
        borderRadius: 4,
        fontFamily: theme.font.secondary,

        '&.MuiTypography-root': {
            // color: theme.colors.white,
            fontWeight: '800',
            fontSize: 16,
        }
    },
}))

export const ExecutionModes: FC<ExecutionModesProps> = props => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const { modes, size } = props;
    const values = messages as Record<string, any>;

    const getDescription = (mode: string) => {
        const title = values[`executionModes.${mode}.title`] || mode;

        return (<Box>
            <Typography variant='subtitle2' style={{ fontWeight: 'bold', fontSize: 14 }}>
                {title}
            </Typography>
            {values[`executionModes.${mode}.description`] && (
                <Typography variant='body2'>
                    {values[`executionModes.${mode}.description`]}
                </Typography>
            )}
        </Box>);
    }

    return (
        <span className={styles.modesContainer}>
            {modes.toSorted().map((mode) => (
                <Tooltip title={getDescription(mode)} arrow enterDelay={1000}>
                    <Typography className={classNames({
                        [styles.normal]: !size || size === 'normal',
                        [styles.small]: size === 'small',
                    })}>
                        {values[`executionModes.${mode}.initial`] || mode[0]}
                    </Typography>
                </Tooltip>
            ))}
        </span>
    )
}
