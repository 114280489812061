import React from 'react';
import { IconButton, IconButtonProps, styled } from '@mui/material';

export interface ExpandButtonProps extends IconButtonProps {
    expand: boolean;
  }

export const ExpandButton = styled((props: ExpandButtonProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
    })(({ theme }) => ({
      alignSelf: 'center',
      maxHeight: '24px',
      maxWidth: '24px',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      variants: [
        {
          props: ({ expand }) => !expand,
          style: {
            transform: 'rotate(0deg)',
          },
        },
        {
          props: ({ expand }) => !!expand,
          style: {
            transform: 'rotate(180deg)',
          },
        },
      ],
    }));
