import { Instance, SnapshotIn, types, flow, getEnv } from 'mobx-state-tree';
import { Subject } from 'rxjs';

import { CreateProcedurePayload } from 'Procedures/domain/CreateProcedurePayload';
import { EditProcedurePayload } from 'Procedures/domain/EditProcedurePayload';
import { ProcedureEditorStore } from './ProcedureEditor.store';
import { IProcedureDeviceUI } from './ProcedureDeviceUI.store';
import { ProcedureGeneralInfo, IProcedureGeneralInfo } from './ProcedureGeneralInfo.store';
import { ScoreData, ScoreDataEntity } from 'Procedures/domain/Device';
import { GetScoreDataParams, performGetScoreData, performSaveScoreData } from 'Procedures/services/scoreData';
import { ProcedureVersionAssignment, assignProcedureVersions} from 'Procedures/services/assignProcedureVersion';

export interface VersionAssignment {
    actorId: string;
    actorName: string;
    actorType: 'user' | 'group';
    versionId: string | null;
    versionName: string | null;
}

export const ProcedureEditorUIStoreInferred = types
    .model('ProcedureEditorUIStoreInferred', {
        _procedure: types.optional(ProcedureEditorStore, {}),
        processToken: types.maybeNull(types.string),
        isLoading: types.optional(types.boolean, false),
        isTokenLoading: types.optional(types.boolean, false),
        procedureModalOpened: types.optional(types.boolean, false),
        deleteConfirmationModalOpened: types.optional(types.boolean, false),
        procedureToEditId: types.maybeNull(types.string),
        currentDeviceIndex: types.optional(types.number, 0),
        errors: types.maybeNull(types.array(types.string)),
        procedureGeneralInfo: types.maybeNull(ProcedureGeneralInfo),
        scoreData: types.maybeNull(types.frozen<ScoreData>()),
        scoreDataEntity: types.maybeNull(types.frozen<ScoreDataEntity>()),
        isScoreDataLoading: types.optional(types.boolean, false),
        scoreDataModalOpened: types.optional(types.boolean, false),
        versionAssignmentModalOpened: types.optional(types.boolean, false),
        isVersionAssignmentLoading: types.optional(types.boolean, false),
    })
    .views(self => {
        return {

            get devices() {
                return self._procedure.devices;
            },

            get isNextStepDisabled() {
                const devices = self._procedure.devices;
                const hasUploadedFile = devices.some(device => device._device?.deviceId);

                return devices.some(device => device.isLoading || device.hasError) || !hasUploadedFile;
            },

            get isNewDeviceAddDisabled() {
                const devices = self._procedure.devices;

                return devices.some(device =>
                    !device._device?.deviceId && !device.isLoading
                );
            },
        }
    })
    .actions(self => {
        const getToken = flow(function* () {
            self.isTokenLoading = true;
            const result = yield self._procedure.getToken();
            self.isTokenLoading = false;

            if (result.success) {
                self.processToken = result.data.token;
            }
        });

        const uploadFile = flow(function* (
            file: File, device: IProcedureDeviceUI, cancelSubject: Subject<{}>
        ) {
            if (self.processToken) {
                const additionalData = { token: self.processToken } as {
                    token: string;
                    procedureId?: string;
                    deviceId?: string;
                };

                if (self.procedureToEditId) {
                    additionalData['procedureId'] = self.procedureToEditId;
                }

                if (device._device?.deviceId) {
                    additionalData['deviceId'] = device._device?.deviceId;
                }

                const result = yield self._procedure.uploadFile(file, additionalData, device, cancelSubject);

                return result;
            }
        });

        const editProcedure = flow(function* (payload: EditProcedurePayload) {
            const result = yield self._procedure.editProcedure(payload);

            if (!result.success) {
                const { body } = result.error || {};

                self.errors = body?.messages;
            } else {
                self.errors = null;
            }

            return result;
        });

        const createProcedure = flow(function* (payload: CreateProcedurePayload) {
            const result = yield self._procedure.createProcedure(payload);

            if (!result.success) {
                const { body } = result.error || {};

                self.errors = body?.messages;
            } else {
                self.errors = null;
            }

            return result;
        });

        const deleteProcedure = flow(function* () {
            if (self.procedureToEditId) {
                return yield self._procedure.deleteProcedure(self.procedureToEditId);
            }
        });

        const exportProcedure = flow(function* (payload) {
            return yield self._procedure.exportProcedure(payload);
        });

        const toggleProcedureModalOpen = (open: boolean) => {
            self.procedureModalOpened = open;
        };

        const toggleDeleteConfirmationModalOpened = (open: boolean) => {
            self.deleteConfirmationModalOpened = open;
        };

        const setEditProcedureId = (id: string | null | undefined) => {
            if (id) {
                self.procedureToEditId = id;
            }
        };

        const clearEditProcedureId = () => self.procedureToEditId = null;

        const addDevice = () => {
            self._procedure.addDevice();
            setCurrentDeviceIndex(self._procedure.devices.length - 1);
        };

        const cancelProcedureFormProcess = flow(function* () {
            self._procedure.clearDevices();

            if (self.processToken) {
                return yield self._procedure.cancelProcedureFormProcess(self.processToken);
            }
        });

        const cancelProcedureDeviceProcess = flow(function* (id: string) {
            if (self.processToken) {
                return yield self._procedure.cancelProcedureDeviceProcess(self.processToken, id);
            }
        });

        const deleteDeviceFromList = (index: number) => {
            self._procedure.deleteDeviceFromList(index);
            setCurrentDeviceIndex(index > 0 ? index - 1 : 0);
        };

        const setProcedureGeneralInfo = (data: IProcedureGeneralInfo) => {
            self.procedureGeneralInfo = data;
        };

        const setCurrentDeviceIndex = (index: number) => {
            self.currentDeviceIndex = index;
        };

        const toggleLoading = (isLoading: boolean) => {
            self.isLoading = isLoading;
        };

        const cleanUpProcedureModal = () => {
            cancelProcedureFormProcess();
            setCurrentDeviceIndex(0);

            self.procedureGeneralInfo = null;
            self.procedureToEditId = null;
            self.processToken = null;
            self.errors = null;
        }

        const loadScoreData = flow(function* (procedureVersionId: string) {
            self.isScoreDataLoading = true;
            const result = yield performGetScoreData(getEnv(self).api, {
                payload: { procedureVersionId },
                errorHandlers: { DEFAULT: '' },
            });

            if (result.success) {
                const entity = result.data[0];
                self.scoreDataEntity = entity;
                self.scoreData = JSON.parse(entity.rawData);
            }
            self.isScoreDataLoading = false;
            return result;
        });

        const saveScoreData = flow(function* () {
            if (!self.scoreDataEntity || !self.scoreData) return;

            const updatedEntity: ScoreDataEntity = {
                ...self.scoreDataEntity,
                maxScore: self.scoreData.maxScore,
                passScore: self.scoreData.passScore,
                executionModes: self.scoreData.executionModes,
                rawData: JSON.stringify(self.scoreData),
            };

            const result = yield performSaveScoreData(getEnv(self).api, {
                payload: { entity: updatedEntity },
                errorHandlers: { DEFAULT: '' },
            });
            return result;
        });

        const updateScoreData = (update: Partial<ScoreData>) => {
            if (self.scoreData) {
                self.scoreData = { ...self.scoreData, ...update };
            }
        };

        const toggleScoreDataModalOpen = (open: boolean) => {
            self.scoreDataModalOpened = open;
        }

        const toggleVersionAssignmentModalOpen = (open: boolean) => {
            self.versionAssignmentModalOpened = open;
        }

        const toggleVersionAssignmentLoading = (loading: boolean) => {
            self.isVersionAssignmentLoading = loading;
        }

        const saveVersionAssignments = flow(function* (payload: ProcedureVersionAssignment[]) {
            self.isVersionAssignmentLoading = true;
            try {
                const result = yield assignProcedureVersions(getEnv(self).api, {
                        payload,
                        errorHandlers: { DEFAULT: '' },
                    });
                self.isVersionAssignmentLoading = false;

                console.dir(result);

                if (!result.success) {
                    const { body } = result.error || {};
                    self.errors = body?.messages;
                } else {
                    self.errors = null;
                }

                return result;

            } catch (error) {
                self.isVersionAssignmentLoading = false;
                return { success: false, error };
            }
        });

        return {
            getToken,
            uploadFile,
            editProcedure,
            createProcedure,
            deleteProcedure,
            exportProcedure,
            toggleProcedureModalOpen,
            toggleDeleteConfirmationModalOpened,
            setEditProcedureId,
            clearEditProcedureId,
            addDevice,
            cancelProcedureFormProcess,
            cancelProcedureDeviceProcess,
            setProcedureGeneralInfo,
            deleteDeviceFromList,
            setCurrentDeviceIndex,
            toggleLoading,
            cleanUpProcedureModal,
            setDevices: self._procedure.setDevices,
            loadProcedure: self._procedure.loadProcedure,
            loadScoreData,
            saveScoreData,
            updateScoreData,
            toggleScoreDataModalOpen,
            toggleVersionAssignmentModalOpen,
            toggleVersionAssignmentLoading,
            saveVersionAssignments,
        }
    });

type ProcedureEditorUIStoreFactoryType = typeof ProcedureEditorUIStoreInferred;
interface IProcedureEditorUIStoreFactory extends ProcedureEditorUIStoreFactoryType { };
export const ProcedureEditorUIStore: IProcedureEditorUIStoreFactory = ProcedureEditorUIStoreInferred;
export interface IProcedureEditorUIStore extends Instance<IProcedureEditorUIStoreFactory> { };
export interface IProcedureEditorUIStoreSnapshotIn extends SnapshotIn<IProcedureEditorUIStore> { };
