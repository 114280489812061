import React, { FC } from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    ColumnDef,
    flexRender,
    TableOptions,
    TableState,
    getFilteredRowModel,
    getExpandedRowModel,
    SortingState,
} from '@tanstack/react-table';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import classNames from 'classnames';

import { TablePagination } from './components/TablePagination';
import { useStyles } from './Table.styles';
import { ThemeProps } from '@styles/theme';
import { Fade, Skeleton } from '@mui/material';

export type PaginationProps = {
    currentPage: number,
    limit: number,
    isFirstPage: boolean,
    isLastPage: boolean,
    totalEntities: number,
    totalPages: number,
};

type Props = {
    renderRowSubComponent?: Function,
    className?: string,
    isLoading?: boolean,
    useSkeleton?: boolean,
    paginationDisabled?: boolean,
    paginationStatus: PaginationProps,
    initialState?: Partial<TableState>;
    fetchData?: (...args: any) => void,
};

type RowData = {
    isDisabled?: boolean,
};

type TableProps<T extends object = {}> = TableOptions<T>;

type Table<T extends RowData> = FC<Props & TableProps<T>>;

type TableParams<T> = {
    columns: ColumnDef<T>[],
    data: T[],
    renderRowSubComponent?: Function,
    className?: string,
    isLoading?: boolean,
    useSkeleton?: boolean,
    paginationDisabled?: boolean,
    paginationStatus: PaginationProps,
    initialState?: Partial<TableState>,
    fetchData: Function,
};

export function Table<T extends object = {}>(tableProps: TableParams<T>){
    const {
        columns,
        data,
        renderRowSubComponent,
        className,
        isLoading,
        useSkeleton,
        paginationDisabled,
        paginationStatus,
        initialState,
        fetchData = () => { },
    } = tableProps;

    const [sorting, setSorting] = React.useState<SortingState>(initialState?.sorting || []);

    const instance = useReactTable(
        {
            columns,
            data,
            autoResetAll: true,
            manualPagination: true,
            manualExpanding: false,
            manualSorting: true,
            enableMultiSort: true,
            enableSortingRemoval: true,
            state: { ...initialState, sorting: sorting },
            getRowCanExpand: _ => renderRowSubComponent !== undefined,
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getExpandedRowModel: getExpandedRowModel(),
            onSortingChange: setSorting,
        }
    );

    const {
        getHeaderGroups,
        getRowModel,
        initialState: {
            pagination
        },
    } = instance;

    const { pageIndex, pageSize } = pagination;

    React.useEffect(() => {
        fetchData({ pageIndex, pageSize, sortBy: sorting });
    }, [pageIndex, pageSize, sorting])

    const goToPage = (pageIndex: number) => fetchData({ pageIndex });

    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const min = (a: number, b: number) => a > 0 && a < b ? a : b;

    return (
        <div
            className={classNames(styles.table, className, { [styles.tableDisabled]: isLoading })}
        >
            <div>
                <div className={styles.header}>
                    {
                        isLoading && !useSkeleton && (
                            <div className={styles.loadingWrapper}>
                                <CircularProgress className={styles.loader} color='inherit' size={24} />
                            </div>
                        )
                    }

                    {getHeaderGroups().map(g => g.headers.map(h => {

                        const { minSize, size, maxSize } = { ...h.column.columnDef, size: h.getSize() };

                        return (
                            <div
                                key={h.column.id}
                                className={classNames(styles.headerCell,
                                    {
                                        [styles.withSorting]: h.column.getCanSort(),
                                    }
                                )}
                                style={{
                                    minWidth: minSize,
                                    maxWidth: maxSize || 'none',
                                    // hack, set with in percents
                                    flexBasis: size ? `${size}%` : 'auto',
                                }}
                                onClick={h.column.getToggleSortingHandler()}
                            >
                                <span>
                                    {flexRender(h.column.columnDef.header, h.getContext())}
                                </span>

                                {
                                    h.column.getCanSort() && !h.column.getIsSorted() && (
                                        <ArrowUpwardOutlinedIcon className={styles.sortPlaceholder} />
                                    )
                                }

                                {
                                    h.column.getCanSort() && h.column.getIsSorted() && (
                                        <div className={styles.sortIndicator}>
                                            <ArrowUpwardOutlinedIcon
                                                className={classNames(styles.sortIcon, { [styles.sortedDesc]: !h.column.getNextSortingOrder() })}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }))}
                </div>

                <div className={styles.body}>
                    {isLoading && useSkeleton ?
                        (
                            Array.from({ length: min(data?.length ?? pageSize, pageSize)! }, () => ({})).map((_, row) => {
                            return (
                            <Fade key={row} in={true} timeout={1000}>
                            <div key={row} className={classNames(styles.row)}>
                                <div className={styles.defaultRowContent}>
                                    {getHeaderGroups().map(g => g.headers.map(cell => {
                                        const { minSize, size, maxSize } = { ...cell.column.columnDef, size: cell.getSize() };
                                        const skeletonWidth = size;
                                        return (
                                            <div
                                                className={styles.cell}
                                                key={cell.id}
                                                style={{
                                                    minWidth: minSize,
                                                    maxWidth: maxSize || 'none',
                                                    // hack, set with in percents
                                                    flexBasis: size ? `${size}%` : 'auto',
                                                }}
                                            >
                                                <Skeleton variant='rectangular' width={skeletonWidth}/>
                                            </div>
                                        )
                                    }))}
                                </div>
                            </div>
                            </Fade>
                        )}))
                        : (getRowModel().rows.map(row => {
                            const rowData = row.original as RowData;
                            return (
                                <Fade in={true} timeout={1000}>
                                <div key={row.id} className={classNames(styles.row, rowData && rowData.isDisabled ? 'disabled' : '')}>
                                    <div className={styles.defaultRowContent}>
                                        {row.getVisibleCells().map(cell => {
                                            const { minSize, size, maxSize } = {...cell.column.columnDef, size: cell.column.getSize()};

                                            return (
                                                <div
                                                    className={styles.cell}
                                                    key={cell.id}
                                                    style={{
                                                        minWidth: minSize,
                                                        maxWidth: maxSize || 'none',
                                                        // hack, set with in percents
                                                        flexBasis: size ? `${size}%` : 'auto',
                                                    }}
                                                >
                                                    <span className={classNames({ [styles.ellipsis]: true })}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {renderRowSubComponent && row.getIsExpanded() && (
                                        <div className={styles.expandedRowContent}>
                                            {renderRowSubComponent({ row })}
                                        </div>
                                    )}
                                </div>
                                </Fade>
                            )
                    })
                )}
                </div>
            </div>
            {!paginationDisabled &&
                (<TablePagination

                    paginationStatus={paginationStatus}
                    goToPage={goToPage}
                />)
            }
        </div>
    )
}