import { SPACING_BASE, RADIUS, FONT_PRIMARY, FONT_SECONDARY } from './constants';
import colors from './colors'
import { Palette } from '@mui/icons-material';
import { light } from '@mui/material/styles/createPalette';

export type ThemeProps = {
  colors: {
    black: string,
    white: string,
    red: string,
    red_transparent: string,
    deep_red: string,
    green: string,
    green_transparent: string,
    yellow: string,
    transp_yellow_hightlight: string,
    violet_darkest: string,
    violet_darker: string,
    violet_primary: string,
    deep_blue: string,
    blue_link: string,
    blue_primary: string,
    blue_neon: string,
    blue_neon_luminating: string,
    grey_text: string,
    grey_darker: string,
    grey_light: string,
    grey_background: string,
    transp_violet: string,
    transp_grey_light: string,
    grey_lighter: string,
    icon_background: string,
    transp_blue_darker: string,
    transp_blue_disabled: string,
    transp_blue: string,
  },
  font: {
    primary: string,
    secondary: string,
  },
  spacing: Function,
  radius: number
}


// declare module '@mui/material/styles' {
//   interface Theme extends ThemeProps { }

//   interface ThemeOptions {
//     colors?: {
//       black?: string,
//       white?: string,
//       red?: string,
//       green?: string,
//       yellow?: string,
//       transp_yellow_hightlight?: string,
//       violet_darkest?: string,
//       violet_darker?: string,
//       violet_primary?: string,
//       deep_blue?: string,
//       blue_link?: string,
//       blue_primary?: string,
//       blue_neon?: string,
//       blue_neon_luminating?: string,
//       grey_text?: string,
//       grey_darker?: string,
//       grey_light?: string,
//       grey_background?: string,
//       transp_violet?: string,
//       transp_grey_light?: string,
//       grey_lighter?: string,
//       icon_background?: string,
//       transp_blue_darker?: string,
//       transp_blue_disabled?: string,
//       transp_blue?: string,
//     },
//     font?: {
//       primary?: string,
//       secondary?: string,
//     },
//     spacing?: Function,
//     radius?: number
//   }
// }

const jssTheme = {
    colors,
    spacing: (multiplier: number) => SPACING_BASE * multiplier,
    radius: RADIUS,
    font: {
        primary: FONT_PRIMARY,
        secondary: FONT_SECONDARY,
    },
    palette: {
        primary: {
            main: colors.violet_primary,
        },
        secondary: {
            main: colors.blue_primary,
        },
        error: {
            main: colors.red,
        },
        warning: {
            main: colors.yellow,
        },
        success: {
            main: colors.green,
        },
        info: {
            main: colors.blue_primary,
        },
        text: {
            primary: colors.grey_text,
            secondary: colors.grey_text,
        },
        background: {
            default: colors.grey_background,
        },
    }
}

export default jssTheme;
