import { Instance, SnapshotIn, types, getSnapshot, applySnapshot, castToReferenceSnapshot } from 'mobx-state-tree';

import { IProcedureDevice, ProcedureDevice } from './ProcedureDevice.store';
import { version } from 'os';

const ProcedureHistory = types.model({
    date: types.string,
    description: types.string,
});

const ProcedureMedia = types.model({
    src: types.string,
    description: types.string,
    height: types.integer,
    width: types.integer,
    size: types.number,
});

const ActorData = types.model({
    assignedVersion: types.maybeNull(types.string),
});

const ProcedureGroup = types.model({
    id: types.string,
    name: types.string,
    data: types.maybeNull(ActorData),
});

const ProcedureUser = types.model({
    id: types.string,
    name: types.string,
    data: types.maybeNull(ActorData),
});

const ProcedureVersionStoreInferred = types.model('ProcedureVersionStoreInferred',{
    id: types.string,
    date: types.string,
    version: types.string,
    editorVersion: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    executionModes: types.optional(types.array(types.string), []),
    languages: types.optional(types.array(types.string), []),
    environments: types.optional(types.array(types.string), []),
    devices: types.optional(types.array(ProcedureDevice), []),
}).views(self => ({
    get getRawDevices() {
        return self.devices.map(device => getSnapshot(device));
    }
}));

export const ProcedureStoreInferred = types
    .model('ProcedureStoreInferred', {
        id: types.optional(types.string, ''),
        name: types.optional(types.string, ''),
        description: types.maybeNull(types.string),
        collaboration: types.maybeNull(types.boolean),
        executionsCount: types.maybeNull(types.number),
        availableModes: types.optional(types.array(types.string), []),
        groups: types.optional(types.array(ProcedureGroup), []),
        users: types.optional(types.array(ProcedureUser), []),
        versions: types.optional(types.array(ProcedureVersionStoreInferred), []),
        procedurePreview: types.maybeNull(ProcedureMedia),
        procedureMedias: types.maybeNull(types.array(ProcedureMedia)),
        companyId: types.optional(types.string, ''),
        ownership: types.maybeNull(types.string),
        createdAt: types.maybeNull(types.string),
        deletedAt: types.maybeNull(types.string),
        updatedAt: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
    })
    .views(self => ({
        get getProcedureData() {
            return getSnapshot(self);
        },

        get getDevices() {
            return self.versions.map(version => version.devices);
        }
    }));

type ProcedureStoreFactoryType = typeof ProcedureStoreInferred;
interface IProcedureStoreFactory extends ProcedureStoreFactoryType { }
export const ProcedureStore: IProcedureStoreFactory = ProcedureStoreInferred;
export interface IProcedureStore extends Instance<IProcedureStoreFactory> { }
export interface IProcedureStoreSnapshotIn extends SnapshotIn<IProcedureStore> { }


type ProcedureVersionStoreFactoryType = typeof ProcedureVersionStoreInferred;
interface IProcedureVersionStoreFactory extends ProcedureVersionStoreFactoryType { }
export const ProcedureVersionStore: IProcedureVersionStoreFactory = ProcedureVersionStoreInferred;
export interface IProcedureVersionStore extends Instance<IProcedureVersionStoreFactory> {}

export interface IProcedureVersionActor extends Instance<typeof ProcedureUser> {}
