import React, { useState } from 'react';
import { Observer } from 'mobx-react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  MenuItem,
  Select,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Divider,
  InputLabel,
  Dialog,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useProcedureUIStore } from '@core/useStores';
import { messages } from 'Procedures/procedures.messages';
import { ProcedureError, GrabError, TimingError, AnyError } from 'Procedures/domain/ProcedureError';
import { toUserFriendlyString } from '@shared/helpers/stringUtils';
import { ThemeProps } from '@styles/theme';
import { useStyles } from './ScoreDataModal.styles';
import { Modal } from '@shared/components/Modal';
import { AddCircle, Backspace, ExpandMore } from '@mui/icons-material';
import { object, string } from 'yup';
import { ScoreVariable } from 'Procedures/domain/Device';
import { Button } from '@shared/components/Button';

interface ErrorHeaderRowProps {
  error: ProcedureError | GrabError | TimingError;
  onDelete: () => void;
  onChange: (key: string, value: any) => void;
}

const ErrorHeaderRow = ({ error, onDelete, onChange }: ErrorHeaderRowProps) => {
  const theme = useTheme<ThemeProps>();
  const styles = useStyles({ theme });

  if(error.repeatablePoints === undefined || error.repeatablePoints.length === 0 || error.repeatablePoints[0] === undefined) {
    error.repeatablePoints = [ { ErrorNumber: 0, PointsValue: error.points }];
  }

  function mapToArray(array: { ErrorNumber: number, PointsValue: number }[]) {
    console.dir(...array.map(obj => obj.ErrorNumber));
    // Find the maximum index to determine the array size
    const maxIndex = Math.max(...array.map(obj => obj.ErrorNumber), 0);
    // Initialize an array filled with 0s
    const result = new Array(maxIndex + 1).fill(0);
    // Populate the array with values from the objects
    array.forEach(({ ErrorNumber, PointsValue }) => {
        result[ErrorNumber] = PointsValue;
    });

    return result;
}

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto auto auto auto auto auto',
      gap: 2,
      alignItems: 'center',
      borderBottom: `1px solid ${styles.divider}`,
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
      p: 1,
      m: -2,
      mb: 2,
      backgroundColor: theme.colors.transp_violet
    }}>

      <Typography variant='h6' color='primary' sx={{ marginRight: 1, marginLeft: 1 }}>{error.id}</Typography>

      <Typography variant="h6" sx={{ color: 'text.secondary', flexGrow: 1 }}>
        {toUserFriendlyString(error.type)}
      </Typography>

      <TextField
        value={mapToArray(error.repeatablePoints).join(', ')}
        onChange={(e) => {
          const points = e.target.value.split(',').map(p => parseInt(p.trim()) || 0);
          const pointsLength = points.length;
          onChange('points', points[0]);
          onChange('repeatablePoints', points.map((p, i) => ({ ErrorNumber: i, PointsValue: p })).filter((p, i) => p.PointsValue > 0 || i == pointsLength - 1));
        }}
        size="small"
        sx={{ width: 150, alignSelf: 'center' }}
        placeholder="Points, comma separated"
        slotProps={{ input: { endAdornment:
          <InputAdornment position="end">
            <Typography variant='body2'>{messages['scoreDataModal.points.label']}</Typography>
          </InputAdornment> } }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={error.active}
            onChange={(e) => onChange('active', e.target.checked)}
            size="small"
          />
        }
        label="Active"
        sx={{ m: 0 }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={error.blocking}
            onChange={(e) => onChange('blocking', e.target.checked)}
            size="small"
          />
        }
        label="Blocking"
        sx={{ m: 0 }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={error.repeatable}
            onChange={(e) => onChange('repeatable', e.target.checked)}
            size="small"
          />
        }
        label="Repeatable"
        sx={{ m: 0 }}
      />

      <Chip
        label={`${error.feedbackObjects?.length ?? 0} Feedback`}
        size="small"
        variant="outlined"
        sx={{ cursor: 'default' }}
      />

      <IconButton size="small" onClick={onDelete}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

interface ChildPropertyRowProps {
  label: string;
  value: any;
  error: AnyError;
  showBasic?: boolean;
  onChange: (value: any) => void;
}

interface BaseChildPropertiesProps {
  index: number;
  error: AnyError;
  onChange: (index: number, key: string, value: any) => void;
}

interface NewVariableDialogProps {
  open: boolean;
  onClose: (name: string, type: string) => void;
}

function NewVariableDialog(props: NewVariableDialogProps) {
  const { onClose, open } = props;
  const [newVariable, setNewVariable] = useState('');

  const handleClose = () => {
    onClose('', '');
  };

  const handleListItemClick = (type: string) => {
    onClose(newVariable, type);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{messages['scoreDataModal.variables.newVariable.dialog.title']}</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem>
          <TextField
            label={messages['scoreDataModal.variables.newVariable.dialog.nameField']}
            size='small'
            autoFocus
            onChange={(e) => setNewVariable(e.target.value)}
            fullWidth
            slotProps={{ inputLabel: {shrink: true}, htmlInput: { step: "0.1" }}}
          />
        </ListItem>
        {['Integer', 'Bool', 'Float', 'String', 'StringFormat', 'Time'].map((v) => (
          <ListItem disablePadding key={v}>
            <ListItemButton disabled={newVariable === ''} onClick={() => handleListItemClick(v)}>
              <ListItemText primary={v} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

const BaseChildProperties = ({index, error, onChange}: BaseChildPropertiesProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <ChildPropertyRow
        label='tags'
        value={error.tags}
        error={error}
        onChange={(newValue: any) => onChange(index, 'tags', newValue)}
      />
      <ChildPropertyRow
        label='description'
        value={error.description}
        error={error}
        onChange={(newValue: any) => onChange(index, 'description', newValue)}
      />
      <ChildPropertyRow
        label='steps'
        value={error.steps}
        error={error}
        onChange={(newValue: any) => onChange(index, 'steps', newValue)}
      />
    </Box>
  );
}

const ChildPropertyRow = ({ label, value, error, onChange }: ChildPropertyRowProps) => {
  const [newTag, setNewTag] = useState('');
  const isBoolean = typeof value === 'boolean';
  const isNumber = typeof value === 'number';

  const renderInput = () => {
    if (label === 'tags') {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
          {(value as string[])?.map((tag, idx) => (
            <Chip
              key={idx}
              label={tag}
              onDelete={() => onChange(value.filter((_: string, i: number) => i !== idx))}
              color='primary'
            />
          ))}
          <TextField
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter' && newTag.trim()) {
                onChange(value ? [...value, newTag.trim()] : [newTag.trim()]);
                setNewTag('');
              }
            }}
            placeholder="Add tag"
            size="small"
            sx={{ width: 120 }}
          />
        </Box>
      );
    }

    if (error.type === 'GrabError' && label === 'grabber') {
      return (
        <Select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          size="small"
          fullWidth
        >
          {['Anything', 'Cursor', 'LeftHand', 'RightHand', 'BothHands', 'AnyHand'].map(option => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </Select>
      );
    }

    if (error.type === 'TimingError' && label === 'countdown') {
      return (
        <TextField
          type="number"
          value={value}
          onChange={(e) => onChange(Number(e.target.value))}
          size="small"
          fullWidth
        />
      );
    }

    if (isBoolean) {
      return (
        <Checkbox
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          size="small"
        />
      );
    }

    return (
      <TextField
        fullWidth
        value={value}
        onChange={(e) => onChange(isNumber ? Number(e.target.value) : e.target.value)}
        size="small"
        type={isNumber ? 'number' : 'text'}
        multiline={label === 'description'}
      />
    );
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 0.5 }}>
      <Typography variant="body2" sx={{ width: 150, flexShrink: 0 }}>
        {toUserFriendlyString(label)}:
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        {renderInput()}
      </Box>
    </Box>
  );
};

export const ScoreDataModal = () => {
  const theme = useTheme<ThemeProps>();
  const styles = useStyles({ theme });
  const procedureUIStore = useProcedureUIStore();

  const [showNewVariable, setShowNewVariable] = useState(false);

  const getChildProperties = (error: ProcedureError) => {
    const headerKeys = new Set(['_ref', 'id', 'active', 'type', 'points', 'blocking', 'repeatable', 'feedbackObjects', 'repeatablePoints', 'steps', 'tags', 'description']);
    return Object.entries(error).filter(([key]) => !headerKeys.has(key));
  };

  return (
    <Observer>
      {() => {
        const handleErrorChange = (index: number, key: string, value: any) => {
          const newErrors = procedureUIStore.scoreData?.errors.map((error, idx) =>
            idx === index ? { ...error, [key]: value } : error
          );
          procedureUIStore.updateScoreData({ errors: newErrors });
        };

        const handleDeleteError = (index: number) => {
          const newErrors = procedureUIStore.scoreData?.errors.filter((_, idx) => idx !== index);
          procedureUIStore.updateScoreData({ errors: newErrors });
        };

        const handleAddNewVariable = (name: string, type: string) => {
          if (!name || !type) {
            setShowNewVariable(false);
            return;
          }

          const newVar: ScoreVariable = {
            name,
            type: type ?? 'Any',
            generic: { x: 0, y: 0, z: 0, w: 0 },
            string: '',
            object: ''
          }
          const variables = procedureUIStore.scoreData?.variables ?? [];
          procedureUIStore.updateScoreData({ variables: [ newVar, ...variables] });
          setShowNewVariable(false);
        }

        const handleDeleteVariable = (index: number) => {
            const newVariables = [...procedureUIStore.scoreData!.variables];
            newVariables.splice(index, 1);
            procedureUIStore.updateScoreData({ variables: newVariables });
        }

        return (
          <Modal
            isOpen={procedureUIStore.scoreDataModalOpened}
            onRequestClose={() => procedureUIStore.toggleScoreDataModalOpen(false)}
            className={styles.modal}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
          >
            <NewVariableDialog
              open={showNewVariable}
              onClose={handleAddNewVariable}
            />
            <DialogTitle sx={{ zIndex: 1 }}>
              <Typography variant="h4" color='primary' sx={{ flexGrow: 1 }}>
                {messages['scoreDataModal.title']}
              </Typography>
            </DialogTitle>

            <DialogContent sx={{ height: '60vh', overflowY: 'auto', paddingBottom: 2 }}>
              {procedureUIStore.scoreData && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  {/* Execution Modes */}
                  <Box sx={{
                      p: 2,
                      borderRadius: 1,
                      border: `1px solid ${styles.divider}`
                    }}>
                      <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                        gap: 3
                      }}>
                        <Typography variant="h6" color='primary'>{messages['scoreDataModal.executionModes.label']}</Typography>
                        {procedureUIStore.scoreData.executionModes.map(mode => (
                          <Chip key={mode} label={mode} />
                        ))}
                      </Box>
                    </Box>

                  {/* Score Data Section */}
                  <Box sx={{
                    p: 2,
                    borderRadius: 4,
                    border: `1px solid ${styles.divider}`
                  }}>
                    <Box sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                      gap: 3
                    }}>
                      <Typography variant="h6" color='primary'>{messages['scoreDataModal.scoringParameters.label']}</Typography>
                      <TextField
                        label={messages['scoreDataModal.maxScore.label']}
                        type="number"
                        size='small'
                        sx={{ backgroundColor: theme.colors.white }}
                        value={procedureUIStore.scoreData.maxScore}
                        onChange={(e) => procedureUIStore.updateScoreData({
                          maxScore: parseFloat(e.target.value)
                        })}
                        fullWidth
                        slotProps={{ inputLabel: {shrink: true}, htmlInput: { step: "0.1" }}}
                      />
                      <TextField
                        label={messages['scoreDataModal.passScore.label']}
                        type="number"
                        size='small'
                        sx={{ backgroundColor: theme.colors.white }}
                        value={procedureUIStore.scoreData.passScore}
                        onChange={(e) => procedureUIStore.updateScoreData({
                          passScore: parseFloat(e.target.value)
                        })}
                        fullWidth
                        slotProps={{ inputLabel: {shrink: true}, htmlInput: { step: "0.1" }}}
                      />
                      <TextField
                        label={messages['scoreDataModal.stepPoints.label']}
                        type="number"
                        size='small'
                        sx={{ backgroundColor: theme.colors.white }}
                        value={procedureUIStore.scoreData.stepPoints}
                        onChange={(e) => procedureUIStore.updateScoreData({
                          stepPoints: parseFloat(e.target.value)
                        })}
                        fullWidth
                        slotProps={{ inputLabel: {shrink: true}, htmlInput: { step: "0.1" }}}
                      />
                    </Box>

                    {/* Step Weights */
                     procedureUIStore.scoreData.stepWeights !== undefined && Object.keys(procedureUIStore.scoreData.stepWeights).length > 0 &&
                     <Box sx={{ mt: 3 }}>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        {messages['scoreDataModal.stepWeights.label']}
                      </Typography>
                      {Object.entries(procedureUIStore.scoreData.stepWeights).map(([step, weight]) => (
                        <Box key={step} sx={{ display: 'flex', gap: 2, mb: 1 }}>
                          <Typography sx={{ width: 100 }}>{step}</Typography>
                          <TextField
                            value={weight}
                            onChange={(e) => {
                              const newWeights = {
                                ...procedureUIStore.scoreData?.stepWeights,
                                [step]: Number(e.target.value)
                              };
                              procedureUIStore.updateScoreData({ stepWeights: newWeights });
                            }}
                            type="number"
                            size="small"
                            sx={{ width: 100 }}
                          />
                        </Box>
                      ))}
                    </Box>
                    }
                  </Box>

                  {/* Variables */
                  <Accordion sx={{ borderRadius: 1, border: `1px solid ${styles.divider}` }}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography component='span' variant="h6" color='primary' sx={{ flexGrow: 1 }}>
                        {messages['scoreDataModal.variables.label']} ({procedureUIStore.scoreData.variables?.length ?? 0})
                      </Typography>
                      <Button
                        sx={{ mr: 2 }}
                        color='primary'
                        variant='contained'
                        startIcon={<AddCircle />}
                        onClick={() => {
                          setShowNewVariable(true);
                          // e.stopPropagation();
                        }
                        }>
                        {messages['scoreDataModal.variables.newVariable']}
                      </Button>
                    </AccordionSummary>
                    <AccordionDetails>
                      {procedureUIStore.scoreData.variables?.map((variable, index) => {
                        const handleVariableChange = (field: 'string' | 'generic' | 'object', newValue: any) => {
                          const newVariables = [...procedureUIStore.scoreData!.variables];
                          newVariables[index] = { ...variable, [field]: newValue };
                          procedureUIStore.updateScoreData({ variables: newVariables });
                        };

                        return (
                          <Box key={variable.name}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                              backgroundColor: index % 2 == 1 ? theme.colors.transp_violet : 'none',
                              pt: 0.5,
                              pb: 0.5,
                              pl: 2, }}>
                            <Typography sx={{ maxWidth: 250, flexGrow: 1 }}>{variable.name}</Typography>
                            <Box sx={{ flexGrow: 1, flexDirection: 'row', display: 'flex', gap: 2 }}>
                              {variable.type === 'Bool' ? (
                                <Checkbox
                                  size='small'
                                  checked={variable.generic.x > 0}
                                  onChange={(e) => handleVariableChange('generic', {
                                    ...variable.generic,
                                    x: e.target.checked ? 1 : 0
                                  })}
                                />
                              ) : variable.type === 'Float' || variable.type === 'Integer' ? (
                                <TextField
                                  type="number"
                                  size='small'
                                  value={variable.generic?.x ?? 0}
                                  onChange={(e) => handleVariableChange('generic', {
                                    ...variable.generic,
                                    x: parseFloat(e.target.value)
                                  })}
                                  fullWidth
                                  slotProps={{ input: {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography variant='body2'>{variable.type}</Typography>
                                      </InputAdornment>
                                    ),
                                  }}}
                                />
                              ) : variable.type === 'String' || variable.type === 'StringFormat' ? (
                                <TextField
                                  value={variable.string}
                                  size='small'
                                  multiline={true}
                                  onChange={(e) => handleVariableChange('string', e.target.value)}
                                  fullWidth
                                  slotProps={{ input: {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography variant='body2' sx={{borderLeft: `1px solid ${styles.divider}`}}>{variable.type}</Typography>
                                      </InputAdornment>
                                    ),
                                  }}}
                                />
                              ) : variable.type === 'Time' ? (
                                <Box sx={{
                                  display: 'grid',
                                  gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                                  flexGrow: 1,
                                  gap: 3 }}>
                                  <FormControl size='small'>
                                    <Select
                                      value={variable.generic?.x > 0 ? 'Timer' : 'Stopwatch'}
                                      onChange={(e) => {
                                        handleVariableChange('generic', {
                                          ...variable.generic,
                                          x: e.target.value === 'Timer' ? 1 : 0
                                        });
                                      }}
                                    >
                                      {['Stopwatch', 'Timer'].map(option => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <TextField
                                    type="number"
                                    value={variable.generic?.y ?? 0}
                                    onChange={(e) => handleVariableChange('generic', {
                                      ...variable.generic,
                                      y: parseFloat(e.target.value)
                                    })}
                                    size="small"
                                    slotProps={{
                                      inputLabel: {shrink: false}, htmlInput: { step: "0.1" },
                                      input: {
                                        endAdornment: (
                                        <InputAdornment position="end">
                                          <Typography variant='body2'>seconds</Typography>
                                        </InputAdornment>
                                      )}}} />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={variable.generic?.z > 0}
                                        onChange={(e) => handleVariableChange('generic', {
                                          ...variable.generic,
                                          z: e.target.checked ? 1 : 0
                                        })}
                                      />
                                    }
                                    label="Repeat"
                                    sx={{ m: 0 }} />
                                </Box>
                              ) : (
                                <TextField
                                  value={variable.object}
                                  size='small'
                                  onChange={(e) => handleVariableChange('object', e.target.value)}
                                  fullWidth
                                  slotProps={{ input: {
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <span>{variable.type}</span>
                                      </InputAdornment>
                                    ),
                                  }}}
                                />
                              )}
                              <Tooltip title="Delete variable">
                                <IconButton
                                  sx={{ width: 32, height: 32, alignSelf: 'center', flexShrink: 0, flexGrow: 0 }}
                                  size='small'
                                  color='error'
                                  onClick={() => handleDeleteVariable(index)}>
                                    <Backspace />
                                  </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                  }

                  {/* Errors Section */}
                  <Accordion sx={{
                    borderRadius: 1,
                    border: `1px solid ${styles.divider}`
                  }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="h6" color='primary'>
                        {messages['scoreDataModal.errors.label']} ({procedureUIStore.scoreData.errors.length})
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                      {procedureUIStore.scoreData.errors.map((error, index) => (
                        <Box
                          key={index}
                          sx={{
                            p: 2,
                            borderRadius: 2,
                            bgcolor: 'background.paper',
                            border: `1px solid ${theme.colors.violet_primary}`,
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
                          }}
                        >
                          <ErrorHeaderRow
                            error={error}
                            onDelete={() => handleDeleteError(index)}
                            onChange={(key: string, value: any) => handleErrorChange(index, key, value)}
                          />

                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <BaseChildProperties index={index} error={error} onChange={handleErrorChange} />
                            <Divider />
                            {getChildProperties(error).map(([key, value]) => (
                              <ChildPropertyRow
                                key={key}
                                label={key}
                                value={value}
                                error={error}
                                onChange={(newValue: any) => handleErrorChange(index, key, newValue)}
                              />
                            ))}
                          </Box>
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
            </DialogContent>

            <DialogActions sx={{
              position: 'sticky',
              bottom: 0,
              borderTop: `1px solid ${styles.divider}`,
              pt: 2,
              pb: 2
            }}>
              <Button color='secondary' onClick={() => procedureUIStore.toggleScoreDataModalOpen(false)}>{messages['procedureModal.cancel']}</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  await procedureUIStore.saveScoreData();
                  procedureUIStore.toggleScoreDataModalOpen(false);
                }}
              >
                {messages['procedureModal.save']}
              </Button>
            </DialogActions>
          </Modal>
        );
      }}
    </Observer>
  );
};