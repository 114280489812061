import React, { FC, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

import { InputLabel } from '../forms/inputs/InputLabel';

import { useStyles, customStyles } from './ArrayDropdown.styles';
import { ThemeProps } from '@styles/theme';

export type ArrayDropdownProps = {
    className?: string,
    fieldId: string,
    values: Array<Partial<OptionType>>,
    options: Array<Partial<OptionType>>,
    components?: object,
    closeMenuOnSelect?: boolean,
    hideSelectedOptions?: boolean,
    onChange?: (values: ReadonlyArray<object>, fieldId: string) => void,
    label?: string;
    placeholder?: string;
    isRequired?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    disabled?: boolean;
    error?: string;
    menuPortalTarget?: HTMLElement | null;
}

export type OptionType = {
    value: string,
    label: string,
    type?: string,
    disabled?: boolean,
}

export const ArrayDropdown: FC<ArrayDropdownProps> = ({
    className,
    label,
    fieldId,
    isRequired,
    values,
    disabled,
    ...props
}) => {
    const theme = useTheme<ThemeProps>();
    const styles = useStyles({ theme });

    const [selectedValues, setSelectedValues] = useState(values);
    const [availableOptions, setAvailableOptions] = useState(props.options);

    useEffect(() => setSelectedValues(values), [values]);
    useEffect(() => setAvailableOptions(props.options), [props.options]);

    const handleChange = (value: ReadonlyArray<Partial<OptionType>>) => {
        setSelectedValues(value.flat());
        props.onChange && props.onChange(value, fieldId);
    }

    const filterOption = (option: OptionType, inputValue: string) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase());

    return (
        <div className={styles.wrapper}>
            {
                label && (
                    <InputLabel
                        label={label}
                        isRequired={isRequired}
                    />
                )
            }
            <Select
                className={classNames(styles.select, className)}
                isDisabled={disabled}
                isMulti
                {...props}
                options={availableOptions}
                onChange={handleChange}
                styles={{ ...customStyles, menuPortal: (base) => ({ ...base, zIndex: 9999 })}}
                value={selectedValues}
                filterOption={filterOption}
                components={{
                    MultiValue: (props) => props.data.label ? (
                        <components.MultiValue
                        {...props}
                        />
                    ) : <></>,
                    ...props.components,
                    MultiValueRemove: ({ data, innerProps: { className, ...rest } }) => !data.disabled ? (
                        <div className={styles.valueRemove} {...rest}>
                            <CloseIcon className={styles.valueRemoveIcon} />
                        </div>
                    ) : <></>,
                }}
            />
        </div>
    )
}
